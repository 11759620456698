/**
 * Calls `String.prototype.toLowerCase` and casts the result to `Lowercase<T>`
 */
export function toLowercase(value: undefined): undefined;
export function toLowercase<T extends string>(value: T): Lowercase<T>;
export function toLowercase<T extends string>(value: T | undefined): Lowercase<T> | undefined;
export function toLowercase<T extends string>(value: T | undefined): Lowercase<T> | undefined {
  if (value === undefined) {
    return undefined;
  }

  return value.toLowerCase() as Lowercase<T>;
}
