import { useFlag } from '@unleash/proxy-client-react';
import type { ReactNode } from 'react';

interface FeatureFlagProps {
  children: ReactNode;
  fallback?: ReactNode;
  name: string;
  invert?: boolean;
}

export function FeatureFlag({ children, fallback = null, name, invert }: FeatureFlagProps) {
  const flag = useFlag(name);
  const isEnabled = (flag && !invert) || (!flag && invert);

  return isEnabled ? children : fallback;
}
