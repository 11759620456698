import type { ReactNode } from 'react';

import { LoadingFullScreen } from '../../components/Loading/LoadingFullScreen';
import { useAppSelector } from '../../store/hooks';

export function RequireUserLoaded(props: { children: ReactNode }) {
  const userLoading = useAppSelector((state) => state.user.loading);

  if (userLoading) {
    return <LoadingFullScreen />;
  }

  return props.children;
}
