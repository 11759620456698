import { FormattedMessage } from 'react-intl';
import { Text, twMerge } from '@pledge-earth/product-language';

import { useAppSelector } from '../../store/hooks';
import type { MessageId } from '../../locales/types';

export function Subheader({ message }: { message: MessageId }) {
  const isMenuCollapsed = useAppSelector((state) => state.settings.isMenuCollapsed);

  return (
    <Text className={twMerge('MenuLeft__subheader', isMenuCollapsed && 'MenuLeft__subheader--hidden')}>
      <FormattedMessage id={message} />
    </Text>
  );
}
