import type { ReactNode } from 'react';
import { captureException } from '@sentry/react';
import { Navigate, useRouteError } from 'react-router-dom';
import { Text, Button } from '@pledge-earth/product-language';

import { Modal } from '../Modal/Modal';

function isModuleNotFoundError(error: unknown): error is Error & { code: string } {
  if (!(error instanceof Error)) {
    return false;
  }

  return (
    ('code' in error && error.code === 'MODULE_NOT_FOUND') ||
    error.message.startsWith('Failed to fetch dynamically imported module')
  );
}

export function RouterErrorBoundary({ children, fallback }: { children?: ReactNode; fallback: ReactNode }) {
  const error = useRouteError();

  // MODULE_NOT_FOUND means a lazy import failed - usually because a new version has been deployed.
  // We don't send this to Sentry since we can't do anything.
  if (isModuleNotFoundError(error)) {
    return (
      <Modal
        title="A new version of the application is available"
        defaultOpen={true}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            window.location.reload();
          }
        }}
        footer={<Button onPress={() => window.location.reload()}>Reload the page</Button>}
      >
        <Text>Please save what you are doing before reloading the page</Text>
      </Modal>
    );
  }

  if (error) {
    captureException(error);
    return fallback;
  }

  return children;
}

export function RouterErrorBoundaryFallback500Redirect({ children }: { children?: ReactNode }) {
  return <RouterErrorBoundary fallback={<Navigate to="/500" replace={true} />}>{children}</RouterErrorBoundary>;
}
