import type { RootState } from '../store';
import { ClientUserAccountStateEnum } from '../../services/graphql/generated';

export const selectEmail = (state: RootState) => state.user.email;
export const selectClientId = (state: RootState) => state.user.client_id;
export const selectClientPublicId = (state: RootState) => state.user.client_public_id;
export const selectEntitlements = (state: RootState) => state.user?.subscription?.entitlements ?? [];
export const selectUserAuthenticated = (state: RootState) => state.user.authenticated;
export const selectUserRequiresCreateProfile = (state: RootState) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- eslint upgrade
  state.user.account_state && state.user.account_state === ClientUserAccountStateEnum.CreateProfile;
export const selectUserRequiresCreateOrganization = (state: RootState) => !state.user.client_is_created;
export const selectUserRequiresInvite = (state: RootState) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- eslint upgrade
  state.user.account_state && state.user.account_state !== ClientUserAccountStateEnum.Authorized;
