import type { LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import type { LinkProps } from '../Link/Link';
import { Link } from '../Link/Link';
import { useIsTestMode } from '../../hooks/useIsTestMode';
import { configureToIfTestMode } from '../../hooks/useNavigateWithTestMode';

export const LinkWithTestMode = forwardRef(({ to, ...props }: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const testModeEnabled = useIsTestMode();

  return <Link ref={ref} to={configureToIfTestMode(testModeEnabled, to)} {...props} />;
});

export const ReactRouterLinkWithTestMode = forwardRef(
  ({ to, ...props }: ReactRouterLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    const testModeEnabled = useIsTestMode();

    return <ReactRouterLink ref={ref} to={configureToIfTestMode(testModeEnabled, to)} {...props} />;
  },
);
