import { useFlagsStatus } from '@unleash/proxy-client-react';
import type { ReactNode } from 'react';

import { LoadingFullScreen } from '../../components/Loading/LoadingFullScreen';

export function RequireFeatureFlags(props: { children: ReactNode }) {
  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingFullScreen />;
  }

  return props.children;
}
