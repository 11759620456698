import { FormattedMessage } from 'react-intl';
import { Layout } from '@pledge-earth/web-components';
import { Link } from '@pledge-earth/product-language';

import { ExternalLink } from '../Link/ExternalLink';
import { CookieYesLink } from '../CookieYesLink/CookieYesLink';

export function Footer() {
  return (
    <Layout.Footer className="bg-transparent text-center">
      <div className="inline-flex gap-4">
        <ExternalLink className="text-default-bold" href="https://www.pledge.io/privacy" noExternalIcon={true}>
          <FormattedMessage id="privacy_policy" />
        </ExternalLink>
        <span>&bull;</span>
        <ExternalLink className="text-default-bold" href="https://www.pledge.io/terms" noExternalIcon={true}>
          <FormattedMessage id="terms" />
        </ExternalLink>
        <span>&bull;</span>
        <CookieYesLink>
          {(props) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link {...props} href="#" className="text-default-bold">
              <FormattedMessage id="cookie-settings" />
            </Link>
          )}
        </CookieYesLink>
      </div>
    </Layout.Footer>
  );
}
