import type { IntlConfig, IntlShape } from 'react-intl';
import { createIntl, createIntlCache } from 'react-intl';
import { captureException } from '@sentry/react';

import { english } from './en-GB';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

let intl: IntlShape;

const handleIntlError = (err: any) =>
  process.env.NODE_ENV === 'production'
    ? captureException(err)
    : // eslint-disable-next-line no-console
      console.error(err);

// Creates a globally accessible intl instance to be used in non-React environment (e.g. redux)
// See https://formatjs.io/docs/react-intl/api#why-imperative-api
export function getIntl(config?: IntlConfig) {
  if (!intl) {
    intl = createIntl(
      {
        locale: config?.locale ?? english.locale,
        messages: config?.messages ?? english.messages,
        onError: handleIntlError,
        textComponent: 'span', // keep this otherwise Antd icons buttons spacing are not correct
      },
      cache,
    );
  }

  return intl;
}
