import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  apiVersion: '',
};

type SettingsState = {
  [Key in keyof typeof INITIAL_STATE as Key]: (typeof INITIAL_STATE)[Key];
};

const { actions, reducer } = createSlice({
  name: 'internalSettings',
  initialState: () => INITIAL_STATE as SettingsState,
  reducers: {
    apiVersionChanged(state, action: PayloadAction<{ apiVersion: string | null | undefined }>) {
      if (!action.payload.apiVersion) {
        return state;
      }

      return { ...state, apiVersion: action.payload.apiVersion };
    },
  },
});

export const { apiVersionChanged } = actions;

export { reducer };
