import Lottie from 'react-lottie';
import { Outlet, useInRouterContext } from 'react-router-dom';
import { Layout } from '@pledge-earth/web-components';
import type { ReactNode } from 'react';
import { Suspense } from 'react';

import { LinkWithTestMode } from '../../components/LinkWithTestMode/LinkWithTestMode';
import { Footer } from '../../components/Footer/Footer';
import animationData from '../../components/LottieAnimations/pledgeLoadingPurple.json';
import { LoadingFullScreen } from '../../components/Loading/LoadingFullScreen';

import './ErrorLayout.scss';

const { Content } = Layout;

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function Header(props: { children: ReactNode }) {
  const inRouterContext = useInRouterContext();

  if (!inRouterContext) {
    return (
      <a href="/" className="Error__logo" aria-label="link to home">
        {props.children}
      </a>
    );
  }

  return (
    <LinkWithTestMode to="/" className="Error__logo" aria-label="link to home">
      {props.children}
    </LinkWithTestMode>
  );
}

// Duplicate of auth layout for now
export function ErrorLayout() {
  return (
    <Layout className="Error">
      <Header>
        <div className="Error__logo__lottie-container">
          <Lottie options={defaultLottieOptions} isClickToPauseDisabled={true} />
        </div>
      </Header>
      <Content>
        <Suspense fallback={<LoadingFullScreen />}>
          <Outlet />
        </Suspense>
      </Content>
      <Footer />
    </Layout>
  );
}
