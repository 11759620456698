import axios from 'axios';
import store from 'store';

const { REACT_APP_AUTH_ENDPOINT } = process.env;

// eslint-disable-next-line @typescript-eslint/require-await -- eslint onboarding
export async function logout() {
  store.remove('accessToken');
  store.remove('rememberMe');
  store.remove('refreshToken');
  store.remove('username');
}

export async function refreshToken(cb?: () => void) {
  const rToken = store.get('refreshToken');
  const username = store.get('username');

  return axios

    .post(`${REACT_APP_AUTH_ENDPOINT}/refresh`, {
      rToken,
      username,
    })
    .then((res) => {
      if (res) {
        const accessToken = res.data.AuthenticationResult.AccessToken;
        store.set('accessToken', accessToken);

        if (cb) cb();

        return accessToken;
      }
      throw new Error('Access Token needed');
    })
    .catch(async (err) => {
      await logout();
      window.location.assign('/auth/sign-in?expired=true');
      throw err;
    });
}

export async function getToken(code: string) {
  return axios.post(`${process.env.REACT_APP_AUTH_ENDPOINT}/token`, { code }).then((result) => {
    store.set('rememberMe', true);
    store.set('accessToken', result.data.access_token);
    store.set('refreshToken', result.data.refresh_token);
    return true;
  });
}

export async function currentAccount(): Promise<any> {
  // Load the user account if there is an access_token or a refresh_token
  if (store.get('accessToken') || store.get('refreshToken')) {
    // If there is only a refresh_token, start by getting new tokens (refresh)
    if (!store.get('accessToken') && store.get('refreshToken')) {
      await refreshToken();
    }

    return axios({
      url: `${REACT_APP_AUTH_ENDPOINT}/get-me`,
      method: 'get',
      data: {},
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${store.get('accessToken')}`,
      },
    })
      .then((result) => result.data)

      .catch(async (err) => {
        // If there is a 401 error and the user has chosen rememberMe, refresh the token
        if (err.response?.status && err.response.status === 401 && store.get('rememberMe')) {
          await refreshToken();

          // retry the request
          return currentAccount();
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
        logout();
        throw err;
      });
  }

  return undefined;
}

export async function login(
  email: string,
  password: string,
  recaptcha: string | null | undefined,
  mfaResendCode?: boolean,
) {
  return axios

    .post(`${REACT_APP_AUTH_ENDPOINT}/login`, {
      email,
      password,
      recaptcha,
      mfaResendCode,
    })
    .then((res) => {
      if (res) {
        const response = {
          logged: false,
          smsMfaRequired: false,
          verifyEmailRequired: false,
          resetPwdRequired: false,
          session: null,
          phoneNumber: null,
        };

        const {
          AccessToken,
          RefreshToken,
          smsMfaRequired,
          verifyEmailRequired,
          resetPwdRequired,
          session,
          phoneNumber,
        } = res.data;

        if (smsMfaRequired) {
          response.smsMfaRequired = true;
          response.session = session;
          response.phoneNumber = phoneNumber;
        } else if (verifyEmailRequired) {
          response.verifyEmailRequired = true;
          response.session = session;
        } else if (resetPwdRequired) {
          response.resetPwdRequired = true;
        } else if (AccessToken) {
          store.set('accessToken', AccessToken);
          store.set('refreshToken', RefreshToken);
          response.logged = true;
        }
        return response;
      }
      return null;
    })
    .catch(() => null);
}

export async function changePassword(oldPassword: string, newPassword: string) {
  if (store.get('accessToken')) {
    return axios({
      url: `${REACT_APP_AUTH_ENDPOINT}/change-password`,
      method: 'post',
      data: {
        oldPassword,
        newPassword,
      },
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${store.get('accessToken')}`,
      },
    })
      .then((res) => {
        if (res) {
          return res;
        }
        return false;
      })
      .catch((err) => {
        throw err;
      });
  }

  return undefined;
}

export async function forgotPassword(email: string) {
  return axios

    .post(`${REACT_APP_AUTH_ENDPOINT}/forgot-password`, {
      email,
    })
    .then((res) => {
      if (res) {
        return res;
      }
      return false;
    })
    .catch((err) => {
      throw err;
    });
}

export async function resetPassword(email: string, password: string, passwordConfirmation: string, code: string) {
  return axios

    .post(`${REACT_APP_AUTH_ENDPOINT}/confirm-forgot-password`, {
      email,
      password,
      passwordConfirmation,
      confirmationCode: code,
    })
    .then((res) => {
      if (res) {
        return res;
      }
      return false;
    })
    .catch((err) => {
      throw err;
    });
}

export async function confirmInvite(
  email: string,
  token: string,
  password: string,
  passwordConfirmation?: string,
  firstName?: string,
  lastName?: string,
) {
  return axios

    .post(`${REACT_APP_AUTH_ENDPOINT}/confirm-invite`, {
      email,
      token,
      password,
      passwordConfirmation,
      firstName,
      lastName,
    })
    .then((res) => {
      if (res) {
        const { AccessToken, RefreshToken } = res.data;
        store.set('accessToken', AccessToken);
        store.set('refreshToken', RefreshToken);
        return true;
      }
      return false;
    })
    .catch((err) => {
      throw err;
    });
}

export async function signUp(email: string, password: string, redirectTo: string | null, originTrackingData: any) {
  return axios

    .post(`${REACT_APP_AUTH_ENDPOINT}/sign-up`, {
      email,
      password,
      redirectTo,
      originTrackingData,
    })
    .then((res) => (res ? res.data : {}))
    .catch((err) => {
      throw err;
    });
}

export async function confirmSignUp(email: string, code: string, session: string) {
  return axios

    .post(`${REACT_APP_AUTH_ENDPOINT}/confirm-sign-up`, {
      email,
      code,
      session,
    })
    .then((res) => {
      if (res?.data?.success && res.data.tokens) {
        const { AccessToken, RefreshToken } = res.data.tokens;
        store.set('accessToken', AccessToken);
        store.set('refreshToken', RefreshToken);
      }
      return res ? res.data : {};
    })
    .catch((err) => {
      throw err;
    });
}

export async function resendConfirmSignUp(email: string) {
  return axios

    .post(`${REACT_APP_AUTH_ENDPOINT}/resend-confirm-sign-up`, {
      email,
    })
    .then((res) => (res ? res.data : {}))
    .catch((err) => {
      throw err;
    });
}

export async function confirmMfa(code: string, session: string, email: string) {
  return axios

    .post(`${REACT_APP_AUTH_ENDPOINT}/confirm-mfa`, {
      code,
      session,
      email,
    })
    .then((res) => {
      if (res) {
        const { AccessToken, RefreshToken } = res.data;
        store.set('accessToken', AccessToken);
        store.set('refreshToken', RefreshToken);
        return true;
      }
      return false;
    })
    .catch((err) => {
      throw err;
    });
}
