import type { RelativeRoutingType, To } from 'react-router-dom';
import { useHref } from 'react-router-dom';

import { useIsTestMode } from './useIsTestMode';
import { configureToIfTestMode } from './useNavigateWithTestMode';

export function useHrefWithTestMode(to: To, options?: { relative?: RelativeRoutingType }) {
  const isTestMode = useIsTestMode();
  const href = useHref(configureToIfTestMode(isTestMode, to), options);

  // see: https://github.com/adobe/react-spectrum/issues/6397
  if (typeof to === 'string' && (to.startsWith('http') || to.startsWith('mailto'))) {
    return to;
  }

  return href;
}
