import { useEffect, useState } from 'react';

import './LoadingGeneric.scss';

/**
 * @field delayMs - Optional delay in milliseconds before the loading animation is shown. Useful for preventing flickering when loading is fast. If not provided, the loading animation is shown immediately.
 */
interface LoadingGenericProps {
  delayMs?: number;
}

/**
 * Mimics the loading animation of the generic loading screen in public app index pages (e.g. index_calculator.html, index_impact.html)
 */
export function LoadingGeneric(props: LoadingGenericProps) {
  const { delayMs: delay } = props;
  const [show, setShow] = useState(!delay);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    if (delay) {
      timeoutId = setTimeout(() => {
        setShow(true);
      }, delay);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [delay, setShow]);

  if (!show) {
    return null;
  }

  return (
    <div className="LoadingGeneric">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
