import type { ReactNode } from 'react';
import { useRef } from 'react';

export function CookieYesLink(props: { children: (props: { onClick: () => void }) => ReactNode }) {
  const ref = useRef<HTMLSpanElement>(null);

  const onClick = () => {
    ref.current?.click();
  };

  return (
    <>
      {props.children({ onClick })}
      <span ref={ref} className="cky-banner-element" />
    </>
  );
}
