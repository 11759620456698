import { locales } from '@pledge-earth/web-components';

import generalMessages from './translations/en.json';
import carrierNames from './translations/carriers.json';

// eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
const { en_GB } = locales;

const combinedMessages = {
  ...carrierNames,
  ...generalMessages,
};

export const english = {
  locale: 'en-GB',
  localeAntd: en_GB,
  messages: combinedMessages,
};
