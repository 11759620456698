import { locales } from '@pledge-earth/web-components';

import messages from './translations/fr.json';

// eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
const { fr_FR } = locales;

export const french = {
  locale: 'fr-FR',
  localeAntd: fr_FR,
  messages,
};
