import Lottie from 'react-lottie';
import { Layout } from '@pledge-earth/web-components';
import type { ReactNode } from 'react';

import { ExternalLink } from '../../components/Link/ExternalLink';
import animationData from '../../components/LottieAnimations/pledgeLoadingPurple.json';

import './LastResort.scss';

const { Content } = Layout;

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

// Duplicate of Error layout for now
export function LastResortLayout({ children }: { children: ReactNode }) {
  return (
    <Layout className="LastResort">
      <a href="/" className="LastResort__logo" aria-label="link to home">
        <div className="LastResort__logo__lottie-container">
          <Lottie options={defaultLottieOptions} isClickToPauseDisabled={true} />
        </div>
      </a>
      <Content>{children}</Content>
      <Layout.Footer className="bg-transparent text-center">
        <div className="flex gap-4">
          <ExternalLink href="https://www.pledge.io/privacy" noExternalIcon={true} variant="monochrome">
            Privacy Policy
          </ExternalLink>
          <span>&bull;</span>
          <ExternalLink href="https://www.pledge.io/terms" noExternalIcon={true} variant="monochrome">
            Terms
          </ExternalLink>
        </div>
      </Layout.Footer>
    </Layout>
  );
}
