import { type PropsWithChildren, useEffect } from 'react';
import { type Store } from '@reduxjs/toolkit';
import { Provider as ReduxProvider } from 'react-redux';

import { sagas } from './sagas';
import { sagaMiddleware } from './store';

export function ReduxSagaProvider({ store, children }: PropsWithChildren<{ store: Store }>) {
  useEffect(() => {
    const task = sagaMiddleware.run(sagas);
    return () => {
      task.cancel();
    };
  }, []);

  return <ReduxProvider store={store}>{children}</ReduxProvider>;
}
