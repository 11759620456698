import { Navigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export function RedirectTo404() {
  const path = useLocation().pathname;

  // Log a warning in Sentry
  Sentry.captureMessage(`Redirect to 404 - path: ${path}`, 'warning');

  return <Navigate to="/404" replace={true} />;
}
