import type { ReactNode } from 'react';
import type { ToastContentProps } from '@pledge-earth/product-language';
import { toastQueue } from '@pledge-earth/product-language';

function showToast({
  description,
  timeout = 2500,
  variant,
  isCloseable = false,
}: {
  description: ReactNode;
  timeout?: number;
  variant: ToastContentProps['variant'];
  isCloseable?: boolean;
  isLoading?: boolean;
}) {
  return toastQueue.add(
    {
      description,
      variant,
      isCloseable,
    },
    {
      timeout,
    },
  );
}

export function showInfoToast({
  description,
  timeout = 2500,
  isCloseable = false,
  isLoading = false,
}: {
  description: ReactNode;
  timeout?: number;
  isCloseable?: boolean;
  isLoading?: boolean;
}) {
  return showToast({
    description,
    variant: 'info',
    timeout,
    isCloseable,
    isLoading,
  });
}

export function showSuccessToast({
  description,
  timeout = 2500,
  isCloseable = false,
  isLoading = false,
}: {
  description: ReactNode;
  timeout?: number;
  isCloseable?: boolean;
  isLoading?: boolean;
}) {
  return showToast({
    description,
    variant: 'success',
    timeout,
    isCloseable,
    isLoading,
  });
}

export function showErrorToast({
  description,
  timeout = 2500,
  isCloseable = false,
  isLoading = false,
}: {
  description: ReactNode;
  timeout?: number;
  isCloseable?: boolean;
  isLoading?: boolean;
}) {
  return showToast({
    description,
    variant: 'error',
    timeout,
    isCloseable,
    isLoading,
  });
}
