import { invoke } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { BannerMessage, Button, Text } from '@pledge-earth/product-language';
import { Helmet } from 'react-helmet';

import { useAppSelector } from '../store/hooks';
import { Modal } from '../components/Modal/Modal';

export function RootLayout() {
  const apiVersion = useAppSelector((state) => state.internalSettings.apiVersion);
  const { pathname } = useLocation();

  const [showRefreshModal, setShowRefreshModal] = useState(false);

  useEffect(() => {
    if (apiVersion && process.env.REACT_APP_SENTRY_RELEASE && apiVersion !== process.env.REACT_APP_SENTRY_RELEASE) {
      setShowRefreshModal(true);
    }
  }, [apiVersion]);

  useEffect(() => {
    // Update the current URL in Intercom
    setTimeout(() => {
      invoke(window, ['dataLayer', 'push'], {
        event: 'routerPathChange',
      });
    }, 1000);
  }, [pathname]);

  return (
    <>
      <Helmet titleTemplate="Pledge | %s" title="Pledge" />
      <Modal
        isOpen={showRefreshModal}
        onOpenChange={setShowRefreshModal}
        title={<FormattedMessage id="alert.version.title" />}
        footer={
          <>
            <Button slot="close">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onPress={() => window.location.reload()}>
              <FormattedMessage id="alert.version.button" />
            </Button>
          </>
        }
      >
        <BannerMessage>
          <Text>
            <FormattedMessage id="alert.version.description" />
          </Text>
        </BannerMessage>
      </Modal>
      <ScrollRestoration />
      <Outlet />
    </>
  );
}
