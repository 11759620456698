import { TrialCountDown } from '../TrialCountDown/TrialCountDown';

import { UserMenu } from './UserMenu/UserMenu';
import './TopBar.scss';

export function TopBar() {
  return (
    <div>
      <div className="TopBar flex h-16 min-h-16 items-center justify-end px-6">
        <TrialCountDown />
        <UserMenu />
      </div>
    </div>
  );
}
