import { Link as ReactRouterLink } from 'react-router-dom';
import type { LinkProps as PledgeLinkProps } from '@pledge-earth/product-language';
import { Link as PledgeLink } from '@pledge-earth/product-language';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

export type LinkProps = PledgeLinkProps<typeof ReactRouterLink>;

export const Link = forwardRef((props: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => (
  <PledgeLink ref={ref} elementType={ReactRouterLink} {...props} />
));
