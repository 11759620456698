import type { ReactNode } from 'react';

import type { ClientRoleEnum } from '../../services/graphql/generated';
import { useAppSelector } from '../../store/hooks';

export function ACL({
  roles = [],
  children,
  fallback = null,
}: {
  roles?: ClientRoleEnum[];
  children: ReactNode;
  fallback?: ReactNode;
}) {
  const { role } = useAppSelector((state) => state.user);

  const authorized = roles.includes(role as ClientRoleEnum);

  if (!authorized) {
    return fallback;
  }

  // if access is successful render children
  return children;
}
