import { Outlet } from 'react-router-dom';
import { Layout } from '@pledge-earth/web-components';
import { Suspense } from 'react';

import { LoadingFullScreen } from '../../components/Loading/LoadingFullScreen';

import './AuthLayout.scss';

const { Content } = Layout;

export function AuthLayout() {
  return (
    <Layout className="Auth">
      <Content>
        <Suspense fallback={<LoadingFullScreen />}>
          <Outlet />
        </Suspense>
      </Content>
    </Layout>
  );
}
