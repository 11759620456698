import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogModal,
  Heading,
  Paragraph,
  Switch,
  twMerge,
} from '@pledge-earth/product-language';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';

import { useNavigateWithTestMode } from '../../hooks/useNavigateWithTestMode';
import { useIsTestMode } from '../../hooks/useIsTestMode';
import {
  ClientUserTipStatusEnum,
  ClientUserTipTypeEnum,
  DismissClientUserTipDocument,
  GetClientUserTipDocument,
} from '../../services/graphql/generated';

function showLiveModePromptModal(
  isTestModeInitially: boolean,
  liveModePromptTipStatus: ClientUserTipStatusEnum,
): boolean {
  // Show prompt only if in live mode initially
  if (isTestModeInitially) {
    return false;
  }

  // Show prompt only if it's not dismissed
  return liveModePromptTipStatus !== ClientUserTipStatusEnum.Dismissed;
}

export function LiveModePrompt(props: { children: ReactNode }) {
  const isTestMode = useIsTestMode();
  const { pathname } = useLocation();
  const navigateWithTestMode = useNavigateWithTestMode();

  // LiveModePrompt is not a mode sensitive ClientUserTip
  // So we always query / mutate it with live mode
  const [liveModePromptTipDismissMutation] = useMutation(DismissClientUserTipDocument);
  const { data: liveModePromptTip } = useQuery(GetClientUserTipDocument, {
    variables: {
      type: ClientUserTipTypeEnum.LiveModePrompt,
    },
  });

  const [isTestModeInitially] = useState<boolean>(isTestMode);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dontShowAgainValue, setDontShowAgainValue] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(
      showLiveModePromptModal(
        isTestModeInitially,
        liveModePromptTip?.client_user_tip.status ?? ClientUserTipStatusEnum.Dismissed,
      ),
    );
  }, [isTestModeInitially, liveModePromptTip?.client_user_tip.status]);

  const switchMode = useCallback(() => {
    navigateWithTestMode(pathname, { testMode: !isTestMode });
  }, [isTestMode, navigateWithTestMode, pathname]);

  const onSubmit = useCallback(async () => {
    setOpenModal(false);

    if (dontShowAgainValue) {
      await liveModePromptTipDismissMutation({
        variables: {
          type: ClientUserTipTypeEnum.LiveModePrompt,
        },
      });
    }
  }, [liveModePromptTipDismissMutation, dontShowAgainValue]);

  // allow through
  return (
    <>
      {props.children}
      <DialogModal
        isOpen={openModal}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            // eslint-disable-next-line no-void
            void onSubmit();
          }
        }}
      >
        <Dialog>
          <DialogBody>
            <div className="pt-12">
              <div className="text-center">
                <Heading level="h3">
                  <FormattedMessage id="liveModePrompt.title" />
                </Heading>

                <Paragraph>
                  <FormattedMessage id="liveModePrompt.description" />
                </Paragraph>
              </div>

              <div className={twMerge('rounded p-4', isTestMode ? 'bg-warning-subdued' : 'bg-default-subdued')}>
                <Switch variant="warning" isSelected={isTestMode} onChange={switchMode} className="gap-x-4">
                  <FormattedMessage id="liveModePrompt.switch" />
                </Switch>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Checkbox onChange={setDontShowAgainValue}>
              <FormattedMessage id="liveModePrompt.checkbox" />
            </Checkbox>
            <Button
              variant={isTestMode ? 'primary-mode' : 'primary'}
              type="submit"
              onPress={onSubmit}
              className="ml-auto"
            >
              <FormattedMessage id="liveModePrompt.continue" />
            </Button>
          </DialogFooter>
        </Dialog>
      </DialogModal>
    </>
  );
}
